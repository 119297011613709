const onClick = (querySelector, callback) => {
    for (let element of document.querySelectorAll(querySelector)) {
      element.addEventListener('click', event => {
        event.preventDefault()
        callback(element, event)
      })
    }
  }

const onTab = (querySelector, callback) => {
  for (let element of document.querySelectorAll(querySelector)) {
    element.addEventListener('keydown', event => {
      if (event.keyCode == 9) {
        callback(element, event)
      }
    })
  }
}

export {
    onClick,
    onTab
}