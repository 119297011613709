import "./main.scss";
import {onClick, onTab} from "./utilities.js";
import translation from "./translation.js";

const initSeaBrand = () => {
  const getConfig = () => {
    const defaultConfig = {
      header: true,
      translation: false,
      search: true,
      footer: true,
      deptName: "",
      deptURL: "",
      containBody: false,
      margins: true,
    };

    return typeof window.seaBrandConfig == "object"
      ? { ...defaultConfig, ...window.seaBrandConfig }
      : defaultConfig;
  };

  let config = getConfig(),
    today = new Date(),
    year = today.getFullYear(),
    root = "https://www.seattle.gov",
    spacer = `<div class='sgSpacer'></div>`,
    globalHeader = `
              <div id="sgGlobalHead" class="sg-grid">
                <a class="sgLogo" href="${root}">Seattle.gov</a>
                ${ config.search ? '<button id="sgSearchToggle">Search</button>' : "" }
              </div>
            `,
    departmentHeader = (() => {
      let name = config.deptURL.length > 0
            ? `<a id="sgDeptName" href="${config.deptURL}">${config.deptName.trim()}</a>`
            : `<span id="sgDeptName">${config.deptName.trim()}</span>`;

      return (config.deptName.length > 0 ) ? `<div id="sgDeptHead" class="sg-grid">${name}</div>` : '';
    })(),
    searchForm = (config.search && config.header) ? `
              <div id="sgSearch">
                <button id="sgSearchClose">Close</button>
                <form role="search" action="${root}/searchresults">
                  <input type="text" title="Search" id="sgSearchInput" name="terms" maxlength="255" placeholder="Search">
                  <button id="sgSearchSubmit" type="submit">Submit Search</button>
                </form>
              </div>
            ` : '',
    header = `
              <header id="sgHead">
                ${ globalHeader }
                ${ departmentHeader }
                ${ searchForm }
              </header>
            `,
    footer = `
              <footer id="sgFoot">
                <p>
                  <a href="${root}/digital">About Our Digital Properties</a>
                  <a href="${root}/tech/initiatives/privacy/privacy-statement">Privacy Policy</a>
                  <a href="${root}/americans-with-disabilities-act">Title II: Americans with Disabilities Act</a>
                  <a href="${root}/civilrights/laws-we-enforce/title-vi-civil-rights-act">Title VI: Civil Rights Act</a>
                </p>
              </footer>
            `;

  const addSearchBindings = () => {
    let search = document.getElementById("sgSearch")?.classList;

    onClick('#sgSearchToggle', () => {
      search?.add("active");
      document.getElementById('sgSearchInput').focus();
    });
    onClick('#sgSearchClose', () => {
      document.getElementById('sgSearchToggle').focus();
      search?.remove("active");
    });
    onClick('#sgSearch', (element, event) => {
      if (event.target.id === 'sgSearch') search?.remove("active");
    });
    onTab('#sgSearchSubmit', (element, event) => {
      event.preventDefault();
      document.getElementById('sgSearchClose').focus();
    });
    onClick('#sgSearchSubmit', (element, event) => {
      document.querySelector('#sgSearch form').submit()
    });
  };

  //console.log(config);

  // Wrap content in container if needed
  if (config.containBody) document.body.innerHTML = `<div class="sg-grid">${document.body.innerHTML}</div>`;

  // Bind HTML to DOM
  if (config.margins) document.body.insertAdjacentHTML('afterbegin', spacer);
  if (config.header) document.body.insertAdjacentHTML('afterbegin', header);
  setTimeout(()=>{
    // Timeout to avoid rendering before dynamic html from angular/jquery/react/vue/etc
    if (config.margins) document.body.insertAdjacentHTML('beforeend', spacer);
    if (config.footer) document.body.insertAdjacentHTML('beforeend', footer);
  }, 1000)


  // Add Translation Functionality
  config.header && config.translation && translation.addTranslation();

  // Bind Search Functionality
  config.header && config.search && addSearchBindings();
};

const initReady = (fn) => {
  if (document.readyState !== 'loading'){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

initReady(() => {
  try {
    initSeaBrand();
    // console.log('Branding Success');
  } catch (error) {
    // console.log('Branding Failure');
    // console.log(error);
  }
});